<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h4 class="m-0">
      {{ myTitle }}
      for&nbsp;
      <span class="test-color" v-if="searchTest.testType">
        {{ searchTest.testType }}
      </span>
    </h4>
    <small v-if="$route.query.type === 'practice'">
      "Modules" are sets of complete English or Math sections. Each test
      consists of 2 English modules and 2 Math modules. An English module
      contains 27 questions, while a Math module contains 22 questions.
    </small>
    <small v-if="$route.query.type === 'drill'">
      "Drills" are sets of English or Math questions. They can contain any
      number of questions, though a drill typically includes 10 questions.
    </small>
    <hr />
    <el-form
      class="mt-4"
      :model="searchTest"
      label-position="right"
      label-width="80px"
      @submit.native.prevent
    >
      <el-form-item label="Type">
        <el-radio
          v-for="(test, index) in Tests"
          :key="index"
          v-model="searchTest.testType"
          @input="search"
          :label="test"
          >{{ test }}</el-radio
        >
      </el-form-item>
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="Name"
          v-model="searchTest.search"
          @keyup.enter.native="search"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="發佈狀態">
      </el-form-item> -->
    </el-form>
    <template v-if="practices">
      <hr />
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          :hide-on-single-page="true"
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <template v-if="practices.length > 0">
        <el-table :data="practices" style="width: 100%" stripe>
          <el-table-column label="Name">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'EducatorModuleBrowse',
                  params: { id: scope.row.id }
                }"
              >
                <b>{{ scope.row.name }}</b>
              </router-link>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Source">
            -
          </el-table-column> -->
          <el-table-column label="Questions" width="100">
            <template slot-scope="scope">
              <template>
                <b class="text-success">{{ scope.row.question_count }}</b>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Date Created" width="150">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column width="400" label="Actions" fixed="right">
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Preview"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'EducatorModuleBrowse',
                    params: { id: scope.row.id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div v-else>
        <el-empty :description="$t('courses.empty-text')"></el-empty>
      </div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          :hide-on-single-page="true"
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import List from "@/views/tests/modules/apis/list.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import Company from "@/apis/company";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {
    return {
      title: this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      Tests: ["SAT", "ACT", "AP", "PSAT", "TOEFL", "IELTS"],
      searchTest: {
        type: null,
        search: null
        // isNew: null,
        // testType: null,
        // subject: null,
        // category: null,
        // is_public: null,
        // status: "draft",
        // company_id: null,
        // source: 3
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      companies: [],
      showLink: false
      // composePractices: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapState("user", ["profile"]),
    sectionType() {
      if (this.$route.params.type === "modules") {
        return "Module";
      } else if (this.$route.params.type === "drills") {
        return "Drill";
      } else {
        return "Module";
      }
    },
    CreateLink() {
      let Link = "";
      if (this.searchTest.type === "drill") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice",
            query: { type: "drill" }
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice",
            query: { type: "drill" }
          };
        }
      } else if (this.searchTest.type === "practice") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice"
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice"
          };
        }
      } else if (this.searchTest.type === "adaptive") {
        Link = {
          name: "SatAddAdaptive"
        };
      } else if (this.searchTest.type === "composePractices") {
        Link = {
          name: "SatAddComposePractice"
        };
      }
      return Link;
    },
    myTitle() {
      if (this.$route.params.type === "modules") {
        return "Modules";
      } else if (this.$route.params.type === "drills") {
        return "Drills";
      } else {
        return "Modules";
      }
    },
    instant() {
      return instant;
    },
    isTeacher() {
      return this.$route.name === "TeacherSatManagement";
    },
    isCompany() {
      return this.$route.name === "CompanySatManagement";
    },
    isAdmin() {
      return this.$route.name === "SatManagement";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      if (this.$route.query.type == "drill") {
        if (this.searchTest.isNew === 1) {
          subjects = [
            { id: 11, name: "english" },
            { id: 12, name: "math" }
          ];
        } else {
          subjects = [
            { id: 5, name: "reading" },
            { id: 6, name: "writing" },
            { id: 7, name: "math" },
            { id: 8, name: "math calculator" }
          ];
        }
      } else {
        if (this.searchTest.isNew === 1) {
          subjects = [
            { id: 13, name: "english" },
            { id: 14, name: "math" }
          ];
        } else {
          subjects = [
            { id: 1, name: "reading" },
            { id: 2, name: "writing" },
            { id: 3, name: "math" },
            { id: 4, name: "math calculator" }
          ];
        }
      }
      return subjects;
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    company_id() {
      let company_id = null;
      if (this.$route.query.company_id) {
        company_id = this.$route.query.company_id;
      }
      return company_id;
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {
    // "searchTest.isNew": {
    //   handler: function() {
    //     this.searchTest.category = this.subjectsNow[0].id;
    //   }
    // }
  },

  async mounted() {
    this.searchTest.testType = this.$route.query.testType
      ? this.$route.query.testType
      : "ACT";
    this.searchTest.search = this.$route.query.search
      ? this.$route.query.search
      : null;
    // this.searchTest.status = this.$route.query.status
    //   ? this.$route.query.status
    //   : "DRAFT";
    // this.searchTest.isNew =
    //   Number(this.$route.query.isNew) > -1
    //     ? Number(this.$route.query.isNew)
    //     : 1;

    // this.searchTest.category = this.$route.query.category
    //   ? this.$route.query.category
    //   : this.subjectsNow[0].id;

    // this.searchTest.source =
    //   Number(this.$route.query.source) > -1
    //     ? Number(this.$route.query.source)
    //     : 3;
    // this.searchTest.company_id =
    //   Number(this.$route.query.company_id) > 0
    //     ? Number(this.$route.query.company_id)
    //     : null;
    // if (!this.searchTest.company_id && !this.isAdmin) {
    //   this.searchTest.status = "PUBLIC";
    // }
    // this.searchTest.type = this.$route.query.type
    //   ? this.$route.query.type
    //   : "composePractices";
    this.getModules();
  },

  methods: {
    async getModules() {
      const res = await List.getModules({
        test_name: this.searchTest.testType,
        exam_type_name: this.sectionType,
        page: this.page,
        per_page: 10,
        search: this.searchTest.search
      });
      this.practices = res.exams.data;
      this.resultsTotal = res.exams.total;
      this.pageSize = res.exams.per_page;
    },
    removeExam(examId) {
      this.$confirm("Are you sure you want to remove this test?", "Remove", {
        confirmButtonText: this.$t("alert.confirm"),
        cancelButtonText: this.$t("alert.cancel"),
        type: "warning"
      })
        .then(async () => {
          await SAT.removeExam(examId);
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.getData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    setLink(id, exam) {
      let practiceType = "composePractices";
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;

      if (exam.category === 9 || exam.category === 15) {
        practiceType = "composePractices";
      } else if (exam.category === 10 || exam.category === 16) {
        practiceType = "adaptivePractices";
      } else {
        practiceType = "singlePractice";
      }
      this.link = `${baseDomainPath}test/${practiceType}/${id}/start`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    getEditLink(type) {
      if (this.isAdmin) {
        return type === "composePractices"
          ? "SatComposePracticeDetail"
          : "SatPracticeDetail";
      } else {
        return type === "composePractices"
          ? "TeacherSatComposePracticeDetail"
          : "TeacherSatPracticeDetail";
      }
    },
    is_mineC(company_id) {
      if (company_id && this.getProfile.company) {
        return this.getProfile.company.id === company_id;
      } else {
        return false;
      }
    },
    async getCompanies() {
      if (this.isAdmin) {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      } else if (this.isTeacher) {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      } else {
        const res = await Company.listCompany({ per_page: 100 });
        this.companies = res.companies.data;
      }
    },
    reGrading(examId) {
      this.$confirm(
        this.$t("management.Are you sure you want to regrade all tests?"),
        this.$t("management.Regrade"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.reGrading(examId);
          this.$message({
            message: "Success!",
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    async copyPractice(type, id) {
      if (type === "composePractices") {
        await SAT.copyComposePractice(id);
      } else {
        await SAT.copyPractice(id);
      }
      await this.getData();
      this.$message({
        message: this.$t("management.Duplicate created"),
        type: "success"
      });
    },
    getQuestionsCount(practices) {
      let count = 0;
      practices.forEach(practice => {
        count += practice.exam.exam_questions_count;
      });
      return count;
    },
    async getData() {
      let res = null;
      let edit_user_id = null;
      if (this.searchTest.type === "composePractices") {
        res = await SAT.getComposePractices({
          // edit_user_id,
          is_new: this.searchTest.isNew,
          page: this.page,
          per_page: 10,
          is_public: this.searchTest.status === "PUBLIC" ? 1 : 0,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          status: this.searchTest.status
          // category: this.searchTest.category
        });
        this.practices = res.compose_practice.data;
        this.resultsTotal = res.compose_practice.total;
        this.pageSize = res.compose_practice.per_page;
      } else if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        // if (this.searchTest.source === 4) {
        res = await SAT.getPractices({
          page: this.page,
          per_page: 10,
          status: this.searchTest.status,
          keyword: this.searchTest.search,
          company_id: this.searchTest.company_id,
          category: this.searchTest.category
        });
        this.practices = res.practices.data;
        this.resultsTotal = res.practices.total;
        this.pageSize = res.practices.per_page;
      }
    },
    async updatePublic(id, title, is_public) {
      if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        await SAT.updatePractice(id, {
          practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      } else if (this.searchTest.type === "composePractices") {
        await SAT.updateComposePractice(id, {
          compose_practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      }
      if (is_public === 1) {
        await this.$message({
          message: this.$t("management.Published!"),
          type: "success"
        });
      } else {
        await this.$message(this.$t("management.Moved to Drafts"));
      }
      this.getData();
    },
    async updateStatus(id, status) {
      await SAT.updateStatus(id, {
        status: status
      });
      await this.$message({
        message: this.$t("management.Published!"),
        type: "success"
      });
      this.getData();
    },
    async search(type) {
      if (type === "changeNew") {
        this.searchTest.subject = null;
      }
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.searchTest,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
